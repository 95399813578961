<template>
  <div>
    <b-card>
      <div class="border rounded p-2">
        <h4 class="mb-1">
          Profile Image
        </h4>
        <b-media no-body>
          <b-media-aside>
            <b-link>
              <b-img
                ref="previewEl"
                rounded
                :src="presenter.profileImage "
                height="250"
                alt=""
                width="250"
              />
            </b-link>
          <!--/ avatar -->
          </b-media-aside>
          <b-media-body class="mt-75 ml-75">
            <!-- upload button -->
            <b-button
              v-model="file"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
              class="mb-75 mr-75"
              :state="Boolean(file)"
              @click="$refs.refInputEl.$el.click()"
            >
              Upload
            </b-button>
            <b-form-file
              ref="refInputEl"
              v-model="profileFile"
              accept=".jpg, .png, .gif"
              :hidden="true"
              plain
              @input="fileSelected"
            />
            <!--/ upload button -->

            <!-- reset -->

            <!--/ reset -->
            <b-card-text>Allowed JPG, GIF or PNG. Max size of 2 MB</b-card-text>
          </b-media-body>
        </b-media>
      </div>
      <b-form-group
        label="Name"
        label-for="name"
        class="my-2"
      >
        <b-form-input
          id="name"
          v-model="presenter.name"
        />

      </b-form-group>
      <b-form-group
        label="About"
        label-for="about"
        class="mt-2"
      >
        <b-form-textarea
          id="about"
          v-model="presenter.bio"
        />

      </b-form-group>
      <b-form-group
        label="Facebook"
        label-for="name"
        class="mt-2"
      >
        <b-form-input
          id="facebook"
          v-model="presenter.facebook"
          class="mt2"
          placeholder="Facebook"
        />
      </b-form-group>
      <b-form-group
        label="Twitter"
        label-for="name"
        class="mt-2"
      >
        <b-form-input
          id="twitter"
          v-model="presenter.twitter"
          class="mt2"
          placeholder="Twitter"
        />
      </b-form-group>
      <b-form-group
        label="Instagram"
        label-for="name"
        class="mt-2"
      >
        <b-form-input
          id="instagram"
          v-model="presenter.instagram"
          class="mt2"
          placeholder="Instagram"
        />
      </b-form-group>
      <b-form-group
        label="Spotify"
        label-for="name"
        class="mt-2"
      >
        <b-form-input
          id="spotify"
          v-model="presenter.spotify"
          class="mt2"
          placeholder="Spotify"
        />
      </b-form-group>
      <b-form-group
        label="Sound Cloud"
        label-for="name"
        class="mt-2"
      >
        <b-form-input
          id="soundcloud"
          v-model="presenter.soundcloud"
          class="mt2"
          placeholder="SoundCloud"
        />
      </b-form-group>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="mt-2 mr-1"
        @click="method === 'edit' ? updatePresenter() : createPresenter()"
      >
        Save changes
      </b-button>
    </b-card>
  </div>
</template>

<script>
import axios from '@axios'
import Ripple from 'vue-ripple-directive'
import {
  BCard, BMediaBody, BFormFile, BButton, BCardText, BMediaAside, BLink, BImg, BMedia, BFormGroup, BFormInput, BFormTextarea,
} from 'bootstrap-vue'
import presenterStoreModule from '../presentersStoreModule'

export default {
  components: {
    BCard,
    BFormTextarea,
    BFormInput,
    BMediaBody,
    BFormFile,
    BButton,
    BCardText,
    BMediaAside,
    BLink,
    BImg,
    BFormGroup,
    BMedia,
  },
  directives: {
    Ripple,
  },
  props: {
    method: {
      type: String,
    },
  },
  data() {
    return {
      file: null,
      profileFile: null,
      storeName: 'presenters',
      presenter: {
      },
    }
  },
  async  mounted() {
    await this.registerStore()
    if (this.method === 'edit') {
      await this.getPresenter()
    }
  },
  destroyed() {
    if (this.$store.hasModule(this.storeName)) this.$store.unregisterModule(this.storeName)
  },
  methods: {
    async fileSelected(payload) {
      const formData = new FormData()
      formData.append('file', payload)
      formData.append('postid', 2)
      formData.append('acc', 3)
      formData.append('platform', 'webadmin')
      this.isLoading = true

      await axios
        .post('/upload/admin', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(response => {
          console.log(response)
          this.presenter.profileImage = response.data.url
        })
        .catch(error => {
          console.log(error)
        })

      this.$mount()
    },
    async getPresenter() {
      const data = await this.$store.dispatch(`${this.storeName}/fetchPresenter`, this.$route.query.id)

      const socialObj = JSON.parse(data.socialLinks)
      data.facebook = socialObj.facebook
      data.twitter = socialObj.twitter
      data.instagram = socialObj.instagram
      data.spotify = socialObj.spotify
      data.soundcloud = socialObj.soundcloud

      this.presenter = data
    },
    async updatePresenter() {
      try {
        const socialObj = {
          facebook: this.presenter.facebook,
          twitter: this.presenter.twitter,
          instagram: this.presenter.instagram,
          spotify: this.presenter.spotify,
          soundcloud: this.presenter.soundcloud,
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of Object.entries(socialObj)) {
          if (value && !value.startsWith('https://')) {
            socialObj[key] = `https://${value}`
          }
        }

        this.presenter.socialLinks = JSON.stringify(socialObj)
        await this.$store.dispatch(`${this.storeName}/updatePresenter`, this.presenter)

        this.$bvToast.toast('Settings has saved successfuly.', {
          title: 'Success',
          autoHideDelay: 5000,
          appendToast: true,
          variant: 'success',
          solid: true,
        })
      } catch (error) {
        this.$bvToast.toast('Getting an error while updating presenter.', {
          title: 'Error',
          autoHideDelay: 5000,
          appendToast: true,
          variant: 'error',
          solid: true,
        })
      }
    },
    createPresenter() {
      try {
        const socialObj = {
          facebook: this.presenter.facebook,
          twitter: this.presenter.twitter,
          instagram: this.presenter.instagram,
          spotify: this.presenter.spotify,
          soundcloud: this.presenter.soundcloud,
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of Object.entries(socialObj)) {
          if (value && !value.startsWith('https://')) {
            socialObj[key] = `https://${value}`
          }
        }

        this.presenter.socialLinks = JSON.stringify(socialObj)
        this.$store.dispatch(`${this.storeName}/createPresenter`, this.presenter)

        this.$bvToast.toast('Settings has saved successfuly.', {
          title: 'Success',
          autoHideDelay: 5000,
          appendToast: true,
          variant: 'success',
          solid: true,
        })

        this.$router.push({ name: 'Presenters' })
      } catch (error) {
        this.$bvToast.toast('Getting an error while updating presenter.', {
          title: 'Error',
          autoHideDelay: 5000,
          appendToast: true,
          variant: 'error',
          solid: true,
        })
      }
    },
    registerStore() {
      if (!this.$store.hasModule(this.storeName)) this.$store.registerModule(this.storeName, presenterStoreModule)
    },
  },
}
</script>
